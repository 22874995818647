import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';
  .ChallengeAccountsItem__Buttons--Desctop {
    display: initial;
    @include respond-to(md) {
      display: none;
    }
  }

  .ChallengeAccountsItem__Buttons--Mobile {
    display: none;

    width: 100%;
    justify-content: flex-end;

    @include respond-to(md) {
      display: flex;
    }
  }
  .ChallengesBlock__body {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .ChallengeAccountsItemWrapper .ReloadIcon {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0aa1e2;
  }

  .ChallengeAccountsItemWrapper .ReloadIcon path {
    stroke: #0aa1e2 !important;
    fill: #0aa1e2 !important;
  }

  .ChallengeAccountsItemWrapper .ReloadIcon svg {
    stroke: #0aa1e2 !important;
    fill: #0aa1e2 !important;
  }

  .ChallengeAccountsItemWrapper .CrossIconDiv {
    background: red;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }

  .CrossIconDiv svg {
    stroke: white;
    fill: white !important;
  }

  .CrossIconDiv path {
    stroke: white;
    fill: white !important;
  }

  .ChallengeAccountsItemWrapper {
    display: grid;
    grid-template-columns: 35% 65%;
    row-gap: 4px;
    background: white;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    transition: all 0.4s ease;
    @include respond-to(md) {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
  }

  .ChallengeAccountsItemWrapper:hover {
    box-shadow: 0 3px 9px 4px rgba(10, 161, 226, 0.25);
    cursor: pointer;
  }

  .ChallengeAccountsItem {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
  }

  .ChallengeAccountsItem--Right {
    justify-content: flex-end;
  }

  .ChallengeAccountsItem__Metrics {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @include respond-to(md) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .ChallengeAccountsItem__Metrics-Item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .ChallengeAccountsItem__Metrics-Item > span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ChallengeAccountsItem--WebTerminalButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ChallengeAccountsItem__Chart {
    @include respond-to(xs) {
      scale: 80%;
    }
  }
`;

export { staticStyles };
