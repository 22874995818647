import React from 'react';

const CrossIcon = () => (
  <div
    style={{
      background: '#EE3235',
      display: 'flex',
      flexDirection: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
    }}>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M11.2679 0.625552C11.434 0.458149 11.7072 0.458149 11.8733 0.625552C12.042 0.792686 12.042 1.06401 11.8733 1.23141L6.60478 6.49963L11.8733 11.7681C12.042 11.9342 12.042 12.2074 11.8733 12.3734C11.7072 12.5422 11.434 12.5422 11.2679 12.3734L5.99945 7.10496L0.731237 12.3734C0.564103 12.5422 0.292777 12.5422 0.125267 12.3734C-0.0420813 12.2074 -0.0420813 11.9342 0.125267 11.7681L5.39413 6.49963L0.125267 1.23141C-0.0420813 1.06428 -0.0420813 0.792954 0.125267 0.625552C0.292509 0.458149 0.563835 0.458149 0.731237 0.625552L5.99945 5.8943L11.2679 0.625552Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CrossIcon;
