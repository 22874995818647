import React, { useMemo } from 'react';
import FIrstPlace from './FirstPlace';
import SecondPlace from './SecondPlace';
import ThirdPlace from './ThirdPlace';

import { staticStyles } from './style';

const PlaceCell = ({ place = 1 }) => {
  const placeIcon = useMemo(() => {
    if (place === 1) {
      return <FIrstPlace />;
    }
    if (place === 2) {
      return <SecondPlace />;
    }
    if (place === 3) {
      return <ThirdPlace />;
    }

    return <div className="PlaceCell__NoIcon">{place}</div>;
  }, [place]);
  return (
    <div className="PlaceCell">
      {placeIcon}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default PlaceCell;
