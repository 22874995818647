import queryString from 'query-string';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NoItemsPanel, PageContentPreloader, PageLayout } from 'common/components';
import { getChallenges } from 'client/challenges/_redux';
import noPayments from 'common/images/noPayments.svg';
import { ChallengesLeaderboardTable, LeaderboardSelector } from '../../_components';
import { getChallegesLeaderboard } from '../../_redux';

const ChallengesLeaderboardPage = ({
  challenges,
  challengesAreLoaded,
  challengesLeaderboard,
  getChallenges,
  challengesLeaderboardAreLoaded,
  getChallegesLeaderboard,
  location,
  history,
}) => {
  const query = useMemo(
    () => ({ page_size: 10, ...queryString.parse(window.location.search) }),
    [window.location.search]
  );
  const getList = useCallback(query => getChallegesLeaderboard({ ...query }), [getChallegesLeaderboard, query]);

  useEffect(() => {
    if (!challengesAreLoaded) {
      getChallenges();
    }
  }, []);

  const [prevReq, setPrevReq] = useState(null);

  useEffect(() => {
    const { challengeId, ...rest } = query;
    const finalQuery = rest;
    if (rest.tab !== 'All') {
      finalQuery.challengeId = challengeId;
      // FIXME: Очень странный баг временный костыль

      if (typeof challengeId === 'undefined') {
        const group = challenges?.find(({ id }) => id === rest.tab);
        if (group) {
          finalQuery.challengeId = group.challenges[0]?.id;
        }
      }
    }
    if (prevReq === challengeId) {
      setPrevReq(challengeId);
      return;
    }
    setPrevReq(challengeId);
    getList({ search: finalQuery });
  }, [getList, query]);

  // console.log('outside', query);

  return (
    <PageLayout captionSlug="justChallengesLeaderboard" showReload={false}>
      <PageContentPreloader ready={challengesLeaderboardAreLoaded} type="bigLine" firstLaunchOnly>
        <LeaderboardSelector challenges={challenges} />
        {challengesLeaderboard.count ? (
          <ChallengesLeaderboardTable
            data={challengesLeaderboard}
            loading={!challengesLeaderboardAreLoaded}
            location={location}
            history={history}
            getList={getList}
            params={{
              search: query,
            }}
          />
        ) : (
          <NoItemsPanel icon={noPayments} captionSlug="noChallengesMatchingThisParameter" />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

ChallengesLeaderboardPage.propTypes = {
  challengesLeaderboard: PropTypes.object.isRequired,
  challengesLeaderboardAreLoaded: PropTypes.bool.isRequired,
  getChallegesLeaderboard: PropTypes.func.isRequired,
  getChallenges: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      challengesAreLoaded: state.client.challenges.challengesAreLoaded,
      challengesLeaderboard: state.client.challenges.challengesLeaderboard,
      challengesLeaderboardAreLoaded: state.client.challenges.challengesLeaderboardAreLoaded,
    }),
    {
      getChallegesLeaderboard: data => getChallegesLeaderboard.request(data),
      getChallenges: () => getChallenges.request(),
    }
  )
)(ChallengesLeaderboardPage);
