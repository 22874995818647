import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .BudgetSelector {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .BudgetSelector__ChooseBudgetText {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .BudgetSelector__Values {
    display: flex;
    gap: 16px;
    border-radius: 32px;
  }

  .BudgetSelector__Values .DefaultButton {
    border-radius: 32px;
    background: white;
  }

  .BudgetSelector__Values .DefaultButton:not(.selected) > span {
    color: #000;
  }

  .BudgetSelector__Values .DefaultButton.selected > span {
    color: white !important;
  }
`;

export { staticStyles };
