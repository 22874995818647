import React from 'react';
import { ChallengeInfoElem } from 'common/components';

import { staticStyles } from './style';

const LeaderboardNameCell = ({ fullName, title = 'Test' }) => (
  <div className="LeaderboardNameCell">
    <div className="LeaderboardNameCell__Name"> {fullName}</div>

    <ChallengeInfoElem small borderColor="blue">
      {title}
    </ChallengeInfoElem>

    <style jsx>{staticStyles}</style>
  </div>
);

export default LeaderboardNameCell;
