import React from 'react';

const secondPlace = () => (
  <div>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0C10.7456 0 0 10.7456 0 24C0 37.2544 10.7456 48 24 48C37.2544 48 48 37.2544 48 24C48 10.7456 37.2544 0 24 0ZM39.3131 39.3131C35.2228 43.4034 29.7844 45.6562 24 45.6562C18.2156 45.6562 12.7772 43.4034 8.68688 39.3131C4.59656 35.2228 2.34375 29.7844 2.34375 24C2.34375 18.2156 4.59656 12.7772 8.68688 8.68688C12.7772 4.59656 18.2156 2.34375 24 2.34375C29.7844 2.34375 35.2228 4.59656 39.3131 8.68688C43.4034 12.7772 45.6562 18.2156 45.6562 24C45.6562 29.7844 43.4034 35.2228 39.3131 39.3131Z"
        fill="#E5E5E5"
      />
      <path
        d="M24 22.7541C25.062 22.7541 25.9228 21.8932 25.9228 20.8312C25.9228 19.7693 25.062 18.9084 24 18.9084C22.9381 18.9084 22.0772 19.7693 22.0772 20.8312C22.0772 21.8932 22.9381 22.7541 24 22.7541Z"
        fill="#898989"
      />
      <path
        d="M31.53 7.59375H16.47C15.5851 7.59375 14.8678 8.31107 14.8678 9.19594C14.8678 10.0808 15.5851 10.7981 16.47 10.7981H31.53C32.4149 10.7981 33.1322 10.0808 33.1322 9.19594C33.1322 8.31107 32.4149 7.59375 31.53 7.59375Z"
        fill="#7C7C7C"
      />
      <path
        d="M30.7819 15.7322L25.1747 19.5497C24.8557 19.7668 24.4787 19.883 24.0928 19.883C23.7069 19.883 23.3299 19.7668 23.0109 19.5497L17.4037 15.7322C17.1447 15.5558 16.9327 15.3189 16.7862 15.042C16.6396 14.765 16.5629 14.4565 16.5628 14.1431V10.7991H31.6228V14.1431C31.6228 14.7797 31.3078 15.3741 30.7819 15.7322Z"
        fill="#D8D8D8"
      />
      <path
        d="M28.1719 10.7981V17.5088L26.5697 18.5991V10.7981H28.1719ZM21.4303 10.7981V18.4744L19.8281 17.3831V10.7981H21.4303Z"
        fill="#BCBCBC"
      />
      <path
        d="M24 40.4063C29.1321 40.4063 33.2925 36.2459 33.2925 31.1138C33.2925 25.9817 29.1321 21.8213 24 21.8213C18.8679 21.8213 14.7075 25.9817 14.7075 31.1138C14.7075 36.2459 18.8679 40.4063 24 40.4063Z"
        fill="#D8D8D8"
      />
      <path
        d="M24 37.6828C27.628 37.6828 30.5691 34.7417 30.5691 31.1138C30.5691 27.4858 27.628 24.5447 24 24.5447C20.372 24.5447 17.431 27.4858 17.431 31.1138C17.431 34.7417 20.372 37.6828 24 37.6828Z"
        fill="#7F7F7F"
      />
      <path
        d="M21.0131 35.5125V34.1719L23.8069 31.229C24.0572 30.9431 24.2559 30.6909 24.4022 30.4725C24.5494 30.2541 24.6553 30.0534 24.7209 29.8706C24.7866 29.6878 24.8194 29.5115 24.8194 29.3409C24.8194 29.0587 24.7434 28.8197 24.5934 28.6228C24.4425 28.4259 24.2166 28.3275 23.9147 28.3275C23.5847 28.3275 23.3409 28.4437 23.1816 28.6762C23.0222 28.9087 22.9434 29.2209 22.9434 29.6147H20.9006L20.8884 29.5791C20.8763 29.0428 20.9925 28.5581 21.2372 28.1259C21.4819 27.6928 21.8334 27.3497 22.2947 27.0956C22.755 26.8415 23.2978 26.7141 23.9213 26.7141C24.5447 26.7141 25.08 26.8219 25.5263 27.0384C25.9734 27.255 26.3166 27.5587 26.5566 27.9497C26.7966 28.3406 26.9166 28.7981 26.9166 29.3231C26.9166 29.6803 26.8556 30.0094 26.7347 30.3094C26.6138 30.6094 26.415 30.93 26.1394 31.2712C25.8628 31.6125 25.4953 32.0259 25.0341 32.5106L23.7234 33.8981L23.7356 33.9281H25.4569L25.5459 33.2072H27.1125V35.5125H21.0131Z"
        fill="#D8D8D8"
      />
    </svg>
  </div>
);

export default secondPlace;
