import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PaginationTable } from 'common/components';
import { withTheme } from 'common/styling/theme';

import PlaceCell from '../PlaceCell';

import { staticStyles, getDynamicStyles } from './style';

import LeaderboardNameCell from '../LeaderboardNameCell';

const ChallengesLeaderboardTable = ({
  data,
  toggleInvestModal,
  loading,
  getList,
  location,
  onRowClick,
  history,
  params,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        accessor: 'place',
        Header: 'justPlace',
        Cell: ({
          row: {
            original: { place },
          },
        }) => <PlaceCell place={place} />,
        isPreviewCell: true,
      },
      {
        accessor: 'name',
        Header: 'justName',
        Cell: ({
          row: {
            original: { challenge, fullName },
          },
        }) => <LeaderboardNameCell title={challenge?.group?.title} fullName={fullName} />,
        isPreviewCell: true,
      },
      {
        accessor: 'profit',
        Header: 'justProfit',
        Cell: ({
          row: {
            original: { profit },
          },
        }) => <div className="ChallengesLeaderboardTable__ProfitCell">{profit}</div>,
      },
      {
        accessor: 'equity',
        Header: 'justEquity',
        Cell: ({
          row: {
            original: { equity },
          },
        }) => <div className="ChallengesLeaderboardTable__EquityCell">{equity}</div>,
      },
      {
        accessor: 'initialAccountSize',
        Header: 'justInitialAccountSize',
        Cell: ({
          row: {
            original: { initialAccountBalance },
          },
        }) => <div className="ChallengesLeaderboardTable__InitialAccountBalanceCell">{initialAccountBalance}</div>,
      },
      {
        accessor: 'gain',
        Header: 'justGain',
        Cell: ({
          row: {
            original: { gain },
          },
        }) => {
          let color = 'black';
          if (gain >= 80) {
            color = '#FFA017';
          }
          if (gain >= 90) {
            color = '#FFCE00';
          }
          return (
            <div style={{ color }} className="ChallengesLeaderboardTable__GainCell">
              {gain}%
            </div>
          );
        },
        isPreviewCell: true,
      },
    ],
    []
  );
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="ChallengesLeaderboardTable">
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ChallengesLeaderboardTable.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

ChallengesLeaderboardTable.defaultProps = {
  onRowClick: () => {},
  theme: {},
};

export default withTheme()(ChallengesLeaderboardTable);
