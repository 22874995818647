import { DefaultButton } from 'common/components';
import React from 'react';

import { staticStyles } from './style';

const LeaderboardBudgetSelector = ({ data, onChange, selectedChallengeId }) => (
  <div className="LeaderboardBudgetSelector">
    <div className="LeaderboardBudgetSelector__Values">
      {data.map(({ id, initialAccountBalance }) => (
        <DefaultButton
          key={`${id} LeaderboardBudgetSelector`}
          selected={selectedChallengeId === id}
          textId={initialAccountBalance}
          noTranslate
          onClick={() => onChange(id)}
        />
      ))}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default LeaderboardBudgetSelector;
