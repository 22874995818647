import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .LeaderboardNameCell {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
