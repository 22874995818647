import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChallengeProgressFailure__ProfitTargetValue {
    position: relative;
  }

  .ChallengeProgressSuccess__ProfitTarget {
    color: #000;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Nadpisi {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .SuccessColor {
    color: #39b841;
  }

  .ChallengeProgressSuccess {
    width: 100%;
    display: flex;
    position: 'relative';
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
  }
  .ChallengeProgressSuccess__ProfitTargetText {
    color: rgba(0, 0, 0, 0.5);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ChallengeProgressSuccess__MiniChart {
    position: relative;
  }

  .ChallengeProgressSuccess__RelativeGrayLine {
    position: relative;
    width: 400px;
    background: #eeeeee;
    border-radius: 30px;
    height: 6px;
  }

  .ChallengeProgressSuccess__RoundSuccess {
    position: absolute;
    height: 6px;
    background: #39b841;
    z-index: 1;
  }

  .ChallengeProgressSuccess__AbsoluteSuccessLine {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #39b841;
    z-index: 2;
    border-radius: 50%;
    top: -5px;
    left: 3px;
  }

  /* not success */

  .ChallengeProgressFailure__ProfitTarget {
    color: #000;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Nadpisi {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .FailureColor {
    color: red;
  }

  .ChallengeProgressFailure {
    width: max-content;
    display: flex;
    position: 'relative';
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    gap: 8px;
  }
  .ChallengeProgressFailure__ProfitTargetText {
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ChallengeProgressFailure__MiniChart {
    position: relative;
  }

  .ChallengeProgressFailure__RelativeGrayLine {
    position: relative;
    width: 400px;
    background: #eeeeee;
    border-radius: 30px;
    height: 6px;
  }

  .ChallengeProgressFailure__RoundFailure {
    position: absolute;
    height: 6px;
    right: 0;
    background: red;
    z-index: 1;
  }

  .ChallengeProgressFailure__AbsoluteFailureLine {
    position: absolute;
    width: 16px;
    height: 16px;
    background: red;
    z-index: 2;
    border-radius: 50%;
    top: -5px;
    right: -3px;
  }

  .ChallengeProgressFailure__ZeroPoint {
    position: absolute;
    right: 0;
    top: 10px;
  }
`;

export { staticStyles };
