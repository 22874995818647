import React from 'react';

const CheckIcon = () => (
  <div
    style={{
      background: '#39B841',
      display: 'flex',
      flexDirection: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
    }}>
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M14.5189 0.896711C14.7157 1.09045 14.7157 1.40918 14.5189 1.60292L6.01933 10.1024C5.82559 10.2993 5.50686 10.2993 5.31312 10.1024L0.812933 5.6027C0.617694 5.40896 0.617694 5.09023 0.812933 4.89649C1.00805 4.69962 1.32459 4.69962 1.51989 4.89649L5.66622 9.04313L13.8126 0.896711C14.0064 0.701096 14.3251 0.701096 14.5189 0.896711Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CheckIcon;
