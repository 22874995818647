import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChallengeInfoElem {
    padding: 8px 16px 8px 16px;
    gap: 10px;
    border-radius: 32px;
    width: max-content;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
  }

  .ChallengeInfoElem--BorderBlue {
    border: 1px solid #0aa1e2;
  }
  .ChallengeInfoElem--Small {
    height: 25px !important;
  }
  .ChallengeInfoElem * {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 13.79px !important;
  }

  .ChallengeInfoElem__Dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
`;

export { staticStyles };
