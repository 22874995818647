import React, { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageLayout, DefaultButton } from 'common/components';

import { ChallengeItem } from 'client/accounts/_components';
import { ModalHOC } from 'common';

import AccountPageOnlyTradingTable from 'client/accounts/AccountPageOnlyTradingTable';

import { ChallengePageChart } from '../../_components';
import { staticStyles } from './style';
import { getChallenge } from '../../_redux';
import CheckIcon from './CheckIcon';
import CrossIcon from './CrossIcon';

const ChallengePage = ({ challenge, challengeIsLoaded, getChallenge, showModal, history }) => {
  const { challengeId } = useParams();
  useEffect(() => {
    getChallenge(challengeId);
  }, []);

  if (!challengeIsLoaded) {
    return null;
  }

  return (
    <PageLayout
      captionSlug={`Challenge ${challenge.challenge?.title}`}
      showReload
      onReloadClick={() => {
        getChallenge(challengeId);
      }}
      reloading={false}>
      <div className="ChallengePageWrapper">
        <div className="ChallengePage__ChallengeConditions">
          <FM id="justChallengeConditions">{txt => <h3>{txt}</h3>}</FM>

          <DefaultButton
            textId="justAboutChallenges"
            onClick={() => {
              showModal({
                caption: { id: 'justAboutChallenges' },
                content: {
                  id: 'justAboutChallengesTogether',
                },
                actionButton: {
                  textId: 'justGoToChallengePage',
                  handler: () => {
                    history.push('/challenges/');
                  },
                },
                cancelButton: {
                  textId: 'justClose',
                },
              });
            }}
          />
        </div>

        <div className="ChallengePage__Description">{challenge.challenge.description}</div>

        <table className="ChallengePage__Table" width="100%">
          <tr>
            <td>
              <FM id="justMinimalTradingDays" />
            </td>
            <td>
              <FM id="justMaximumDailyLoss" />
            </td>
            <td>
              <FM id="justMaximumLoss" />
            </td>
            <td>
              <FM id="justProfitTarget" />
            </td>
          </tr>
          <tr>
            <td>{challenge.level.minimum_trading_days}</td>
            <td>{`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}</td>
            <td>{`${challenge.level.maximum_total_loss.amount} ${challenge.level.maximum_total_loss.currency}`}</td>
            <td>{`${challenge.level.target.amount} ${challenge.level.target.currency}`}</td>
          </tr>
        </table>

        {challengeIsLoaded && <ChallengeItem activeChallenge={challenge} noRedirect />}

        <div className="ChallengePageChart__Under">
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumDailyLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumDailyLoss?.amount} ${challenge.maximumDailyLoss?.currency}`}
              {challenge.maximumDailyLoss?.amount < challenge.level.maximum_daily_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumTotalLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumTotalLoss?.amount} ${challenge.maximumTotalLoss?.currency}`}
              {challenge.maximumTotalLoss?.amount > challenge.level.maximum_total_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justTotalProfit" />
            <div className="ChallengePageChart__Value">
              {`${challenge.totalProfit.amount} ${challenge.totalProfit.currency}`}
              {+challenge.totalProfit.amount >= 0 ? <CheckIcon /> : <CrossIcon />}
            </div>
          </div>
        </div>
      </div>
      {challengeIsLoaded && (
        <div className="ChallengePage__Chart">
          <ChallengePageChart chart={challenge.chart} challenge={challenge} />
        </div>
      )}
      {challengeIsLoaded && (
        <AccountPageOnlyTradingTable
          accountPlatformSlug={challenge.account.platformSlug}
          accountId={challenge.account.id}
        />
      )}
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

export default compose(
  withRouter,
  ModalHOC,
  connect(
    state => ({
      challenge: state.client.challenges.challenge,
      challengeIsLoaded: state.client.challenges.challengeIsLoaded,
    }),
    {
      getChallenge: id => getChallenge.request(id),
    }
  )
)(ChallengePage);
