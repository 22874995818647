import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { closeModal, openModal } from 'interface/user/_redux/actions';
import { PageTabs, TranslatedNavLink, PageContentPreloader, Calendar, NoItemsPanel } from 'common/components';
import noOrdersImage from 'common/images/noOrders.svg';
import { withTheme } from 'common/styling/theme';
import { getTradingAccountOperations } from '../../_redux/actions';

import TradeOperationsTable from '../../_components/TradeOperationsTable';

import { staticStyles, getDynamicStyles } from './style';

const startDate = moment().add(0, 'day').startOf('day').unix();

const endDate = moment().add(1, 'day').startOf('day').unix();

const startCalendar = moment().add(0, 'day').startOf('day');

const endCalendar = moment().add(1, 'day').startOf('day');

const AccountPageOnlyTradingTable = ({
  accountPlatformSlug,
  accountId,
  getOperations,
  operationsAreLoaded,
  operations,
  match,
  openOrders,
  closedOrders,
  delayedOrders,
  equityExist,
  theme,
}) => {
  const { challengeId } = match.params;

  const isMt5Account = accountPlatformSlug === 'mt5';
  const isCfhAccount = accountPlatformSlug === 'cfh';
  const isMt4Account = accountPlatformSlug === 'mt4';

  const OPERATION_STATUSES_BY_SLUG = {
    cfh: 'closed',
    mt5: 'closed',
  };

  const getOperationStatus = useCallback(
    slug => match.params.operationStatus ?? OPERATION_STATUSES_BY_SLUG[slug],
    [OPERATION_STATUSES_BY_SLUG, match.params.operationStatus]
  );

  const convertedPendingStatus = isMt5Account ? 'deferred' : 'delayed';

  const operationStatus = useMemo(() => {
    const status = getOperationStatus(accountPlatformSlug);

    return status === 'pending' ? convertedPendingStatus : status;
  }, [getOperationStatus, accountPlatformSlug, convertedPendingStatus]);

  const memoizedOnChangeParams = useMemo(
    () => ({ id: Number(accountId), operationStatus }),
    [accountId, operationStatus]
  );

  useEffect(() => {
    getOperations(accountId, operationStatus, startDate, endDate);
  }, [getOperations, accountId, operationStatus]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <PageContentPreloader ready>
      {equityExist && (
        <>
          <div className="AccountPageOnlyTradingTable__top-line">
            <PageTabs>
              {!(isMt5Account || isCfhAccount) && openOrders && (
                <TranslatedNavLink id="justOpen" to={`/challenges/${challengeId}`} />
              )}
              {closedOrders && (
                <TranslatedNavLink
                  id={isMt5Account || isCfhAccount ? 'justDeals' : 'justClosed'}
                  to={isMt5Account || isCfhAccount ? `/challenges/${challengeId}` : `/challenges/${challengeId}/closed`}
                />
              )}
              {delayedOrders && <TranslatedNavLink id="justPending" to={`/challenges/${challengeId}/pending`} />}
            </PageTabs>
            <div className="AccountPageOnlyTradingTable__date">
              {operationStatus !== 'delayed' && (
                <Calendar
                  // only mt4
                  daysLimit={operationStatus === 'closed' && isMt4Account ? 1 : 31}
                  onChangeFunc={getOperations}
                  onChangeParams={memoizedOnChangeParams}
                  defaultStartDate={startCalendar}
                  defaultEndDate={endCalendar}
                />
              )}
            </div>
          </div>
          <div className="AccountPageOnlyTradingTable__table">
            <PageContentPreloader ready={operationsAreLoaded} type="smallLines">
              {operations?.trades?.length ? (
                <TradeOperationsTable
                  data={operations.trades}
                  summary={operations.summary}
                  operationStatus={operationStatus}
                  isClient
                />
              ) : (
                <NoItemsPanel icon={noOrdersImage} captionSlug="yotHaveNoOrdersYet" />
              )}
            </PageContentPreloader>
          </div>
        </>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageContentPreloader>
  );
};

AccountPageOnlyTradingTable.defaultProps = {
  operations: {},
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => {
      const { openOrders, closedOrders, delayedOrders } = state.interfaceConfig.equityTradingHistoryDisplay;

      const equityExist = [openOrders, closedOrders, delayedOrders].some(display => display);

      const operations = state.client.common.currentAccountOperations;
      const operationsAreLoaded = state.client.common.currentAccountOperationsAreLoaded;

      return {
        operations,
        operationsAreLoaded,
        openOrders,
        closedOrders,
        delayedOrders,
        equityExist,
        modals: state.user.modals,
        addons: state.interfaceConfig.addons,
      };
    },
    {
      getOperations: (id, operationStatus, startDate, endDate) =>
        getTradingAccountOperations.request(id, operationStatus, startDate, endDate),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
    }
  )
)(AccountPageOnlyTradingTable);
