import classNames from 'classnames';
import React from 'react';

import { staticStyles } from './style';

const ChallengeInfoElem = ({ small, borderColor, innerDotColor, children }) => (
  // FIXME: Border не проставляется
  <div
    className={classNames('ChallengeInfoElem', {
      'ChallengeInfoElem--BorderBlue': borderColor,
      'ChallengeInfoElem--Small': small,
    })}
    style={{ border: `1px solid ${borderColor} !important` }}>
    {innerDotColor && <div className="ChallengeInfoElem__Dot" style={{ background: innerDotColor }} />}
    {children}
    <style jsx>{staticStyles}</style>
  </div>
);
export default ChallengeInfoElem;
