import React from 'react';

const thirdPlace = () => (
  <div>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0C10.7456 0 0 10.7456 0 24C0 37.2544 10.7456 48 24 48C37.2544 48 48 37.2544 48 24C48 10.7456 37.2544 0 24 0ZM39.3131 39.3131C35.2228 43.4034 29.7844 45.6562 24 45.6562C18.2156 45.6562 12.7772 43.4034 8.68688 39.3131C4.59656 35.2228 2.34375 29.7844 2.34375 24C2.34375 18.2156 4.59656 12.7772 8.68688 8.68688C12.7772 4.59656 18.2156 2.34375 24 2.34375C29.7844 2.34375 35.2228 4.59656 39.3131 8.68688C43.4034 12.7772 45.6562 18.2156 45.6562 24C45.6562 29.7844 43.4034 35.2228 39.3131 39.3131Z"
        fill="#FFB031"
      />
      <path
        d="M24 22.7541C25.0619 22.7541 25.9228 21.8932 25.9228 20.8312C25.9228 19.7693 25.0619 18.9084 24 18.9084C22.9381 18.9084 22.0772 19.7693 22.0772 20.8312C22.0772 21.8932 22.9381 22.7541 24 22.7541Z"
        fill="#D6510F"
      />
      <path
        d="M31.53 7.59375H16.47C15.5851 7.59375 14.8678 8.31107 14.8678 9.19594C14.8678 10.0808 15.5851 10.7981 16.47 10.7981H31.53C32.4149 10.7981 33.1322 10.0808 33.1322 9.19594C33.1322 8.31107 32.4149 7.59375 31.53 7.59375Z"
        fill="#B24E1D"
      />
      <path
        d="M30.7819 15.7322L25.1747 19.5497C24.8557 19.7668 24.4787 19.883 24.0928 19.883C23.7069 19.883 23.3299 19.7668 23.0109 19.5497L17.4037 15.7322C17.1447 15.5558 16.9327 15.3189 16.7862 15.042C16.6396 14.765 16.5629 14.4565 16.5628 14.1431V10.7991H31.6228V14.1431C31.6228 14.7797 31.3078 15.3741 30.7819 15.7322Z"
        fill="#FFA017"
      />
      <path
        d="M28.1719 10.7981V17.5088L26.5697 18.5991V10.7981H28.1719ZM21.4303 10.7981V18.4744L19.8281 17.3831V10.7981H21.4303Z"
        fill="#FF7300"
      />
      <path
        d="M24 40.4062C29.1321 40.4062 33.2925 36.2459 33.2925 31.1137C33.2925 25.9816 29.1321 21.8212 24 21.8212C18.8679 21.8212 14.7075 25.9816 14.7075 31.1137C14.7075 36.2459 18.8679 40.4062 24 40.4062Z"
        fill="#FFA017"
      />
      <path
        d="M24 37.6828C27.628 37.6828 30.5691 34.7417 30.5691 31.1138C30.5691 27.4858 27.628 24.5447 24 24.5447C20.372 24.5447 17.4309 27.4858 17.4309 31.1138C17.4309 34.7417 20.372 37.6828 24 37.6828Z"
        fill="#FFF0C5"
      />
      <path
        d="M23.9559 35.5753C23.3878 35.5753 22.8647 35.4769 22.3866 35.28C21.9075 35.0831 21.5278 34.7944 21.2456 34.4138C20.9634 34.0322 20.8284 33.5663 20.8406 33.0141L20.8463 32.9784H22.8834C22.8834 33.1613 22.9275 33.3291 23.0147 33.4819C23.1019 33.6347 23.2266 33.7566 23.3869 33.8484C23.5472 33.9403 23.7375 33.9853 23.9559 33.9853C24.2972 33.9853 24.5681 33.8859 24.7688 33.6872C24.9694 33.4884 25.0697 33.2306 25.0697 32.9128C25.0697 32.595 24.96 32.3203 24.7425 32.1113C24.5241 31.9031 24.2063 31.7981 23.7891 31.7981H22.9013V30.255H23.8481C24.0825 30.255 24.2803 30.2128 24.4406 30.1266C24.6019 30.0413 24.7238 29.9222 24.8072 29.7694C24.8906 29.6166 24.9319 29.4384 24.9319 29.2359C24.9319 28.95 24.8475 28.7138 24.6788 28.5272C24.51 28.3406 24.2691 28.2469 23.955 28.2469C23.7759 28.2469 23.6166 28.2825 23.475 28.3538C23.3344 28.425 23.2228 28.5234 23.1413 28.6491C23.0597 28.7738 23.0194 28.92 23.0194 29.0869H20.9878L20.9756 29.0513C20.9597 28.575 21.0778 28.1559 21.33 27.7941C21.5822 27.4331 21.9347 27.1519 22.3875 26.9513C22.8403 26.7506 23.3541 26.6503 23.9306 26.6503C24.8597 26.6503 25.6078 26.8641 26.1731 27.2906C26.7394 27.7172 27.0216 28.32 27.0216 29.0981C27.0216 29.5313 26.9072 29.9016 26.6794 30.2091C26.4506 30.5166 26.1103 30.78 25.6575 30.9984C26.1141 31.1691 26.4788 31.4269 26.7506 31.7728C27.0225 32.1188 27.1584 32.5313 27.1584 33.0122C27.1584 33.54 27.0197 33.9956 26.7413 34.3791C26.4628 34.7625 26.0831 35.0569 25.6003 35.2641C25.1175 35.4703 24.5691 35.5734 23.9531 35.5734L23.9559 35.5753Z"
        fill="#FFA017"
      />
    </svg>
  </div>
);

export default thirdPlace;
