import React from 'react';

const FIrstPlace = () => (
  <div>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0C10.7456 0 0 10.7456 0 24C0 37.2544 10.7456 48 24 48C37.2544 48 48 37.2544 48 24C48 10.7456 37.2544 0 24 0ZM39.3131 39.3131C35.2228 43.4034 29.7844 45.6562 24 45.6562C18.2156 45.6562 12.7772 43.4034 8.68688 39.3131C4.59656 35.2228 2.34375 29.7844 2.34375 24C2.34375 18.2156 4.59656 12.7772 8.68688 8.68688C12.7772 4.59656 18.2156 2.34375 24 2.34375C29.7844 2.34375 35.2228 4.59656 39.3131 8.68688C43.4034 12.7772 45.6562 18.2156 45.6562 24C45.6562 29.7844 43.4034 35.2228 39.3131 39.3131Z"
        fill="#FFDF43"
      />
      <path
        d="M24 22.7541C25.0619 22.7541 25.9228 21.8932 25.9228 20.8313C25.9228 19.7693 25.0619 18.9084 24 18.9084C22.9381 18.9084 22.0772 19.7693 22.0772 20.8313C22.0772 21.8932 22.9381 22.7541 24 22.7541Z"
        fill="#F47A00"
      />
      <path
        d="M31.53 7.59375H16.47C15.5852 7.59375 14.8678 8.31107 14.8678 9.19594C14.8678 10.0808 15.5852 10.7981 16.47 10.7981H31.53C32.4149 10.7981 33.1322 10.0808 33.1322 9.19594C33.1322 8.31107 32.4149 7.59375 31.53 7.59375Z"
        fill="#4D7382"
      />
      <path
        d="M30.7819 15.7322L25.1747 19.5497C24.8557 19.7668 24.4787 19.883 24.0928 19.883C23.7069 19.883 23.3299 19.7668 23.0109 19.5497L17.4037 15.7322C17.1447 15.5559 16.9327 15.3189 16.7862 15.042C16.6396 14.765 16.5629 14.4565 16.5628 14.1431V10.7991H31.6228V14.1431C31.6228 14.7797 31.3078 15.3741 30.7819 15.7322Z"
        fill="#FFCE00"
      />
      <path
        d="M28.1719 10.7981V17.5088L26.5697 18.5991V10.7981H28.1719ZM21.4303 10.7981V18.4744L19.8281 17.3831V10.7981H21.4303Z"
        fill="#FF9700"
      />
      <path
        d="M25.1081 22.3369C25.3107 22.5782 25.5866 22.7467 25.8937 22.8167C26.2009 22.8868 26.5225 22.8546 26.8097 22.725C27.6291 22.3556 28.5834 22.8159 28.8056 23.6859C28.8838 23.9912 29.0593 24.2626 29.3056 24.459C29.552 24.6554 29.8557 24.7662 30.1706 24.7744C31.0688 24.7978 31.7297 25.6256 31.5525 26.5069C31.4905 26.8158 31.5309 27.1365 31.6676 27.4204C31.8043 27.7043 32.0298 27.9358 32.31 28.08C33.1097 28.4906 33.345 29.5228 32.8031 30.24C32.6129 30.4912 32.5099 30.7977 32.5099 31.1128C32.5099 31.4279 32.6129 31.7344 32.8031 31.9856C33.345 32.7028 33.1097 33.735 32.31 34.1456C32.0298 34.2898 31.8043 34.5213 31.6676 34.8052C31.5309 35.0891 31.4905 35.4098 31.5525 35.7187C31.7297 36.6 31.0697 37.4278 30.1706 37.4512C29.5238 37.4681 28.9659 37.9125 28.8056 38.5397C28.5834 39.4106 27.6291 39.87 26.8097 39.5006C26.5225 39.3712 26.2009 39.3391 25.8938 39.4092C25.5867 39.4792 25.3108 39.6476 25.1081 39.8887C24.5297 40.5769 23.4703 40.5769 22.8928 39.8887C22.6902 39.6474 22.4144 39.4789 22.1072 39.4089C21.8001 39.3388 21.4784 39.371 21.1912 39.5006C20.3719 39.87 19.4175 39.4097 19.1953 38.5397C19.1172 38.2345 18.9416 37.963 18.6953 37.7666C18.449 37.5702 18.1453 37.4595 17.8303 37.4512C16.9322 37.4278 16.2712 36.6 16.4484 35.7187C16.5104 35.4098 16.47 35.0891 16.3333 34.8052C16.1966 34.5213 15.9711 34.2898 15.6909 34.1456C14.8912 33.735 14.6559 32.7028 15.1978 31.9856C15.3881 31.7344 15.491 31.4279 15.491 31.1128C15.491 30.7977 15.3881 30.4912 15.1978 30.24C14.6559 29.5228 14.8912 28.4906 15.6909 28.08C15.9711 27.9358 16.1966 27.7043 16.3333 27.4204C16.47 27.1365 16.5104 26.8158 16.4484 26.5069C16.2712 25.6256 16.9313 24.7978 17.8303 24.7744C18.4772 24.7575 19.035 24.3131 19.1953 23.6859C19.4175 22.815 20.3719 22.3556 21.1912 22.725C21.4785 22.8544 21.8 22.8865 22.1072 22.8164C22.4143 22.7464 22.6901 22.578 22.8928 22.3369C23.4712 21.6487 24.5306 21.6487 25.1081 22.3369Z"
        fill="#FFCE00"
      />
      <path
        d="M24 37.6828C27.628 37.6828 30.5691 34.7417 30.5691 31.1137C30.5691 27.4857 27.628 24.5447 24 24.5447C20.372 24.5447 17.4309 27.4857 17.4309 31.1137C17.4309 34.7417 20.372 37.6828 24 37.6828Z"
        fill="#FFF0C5"
      />
      <path
        d="M21.4209 35.4506V34.2291L22.9641 34.0388V28.5581L21.4097 28.5403V27.3844L25.0491 26.7769V34.0378L26.5922 34.2281V35.4497H21.4219L21.4209 35.4506Z"
        fill="#FFCE00"
      />
    </svg>
  </div>
);

export default FIrstPlace;
