import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .PlaceCell__NoIcon {
    border: 2.5px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    border-radius: 50%;
    height: 48px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
